import * as React from "react"
import Page from '../components/page/Page';
import '../styles/styles.scss';

const Shows = () => {
  return (
    <Page>
      <main className="sds-content-wrapper" style={{ flex: '1' }} >
        <h1>Upcoming Live shows</h1>
        <h2>Next up:</h2>
        <p>Currently no shows are on the agenda.</p>
        <h2>Agenda:</h2>
        <p>Currently no shows are on the agenda.</p>
        <h3>Book a show</h3>
        <p>Best way to get in touch is to send a message on instagram or an email.</p>
      </main>
    </Page>
  )
}

export default Shows

export const Head = () => <title>Skralan 🎸🎵 - Shows</title>
